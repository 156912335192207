import { useApiFetch } from '#imports'
import { AnnotationApi, AuthApi, PatientApi } from '~/api'
import { FileApi } from '~/api/file.api'

export function useApi() {
  const apiFetch = useApiFetch()
  const auth = new AuthApi(apiFetch)
  const annotation = new AnnotationApi(apiFetch)
  const file = new FileApi(apiFetch)
  const patient = new PatientApi(apiFetch)

  return {
    apiFetch,
    auth,
    annotation,
    patient,
    file,
  }
}
