import type { $Fetch } from 'ofetch'

export class AuthApi {
  constructor(private readonly apiFetch: $Fetch) {}

  async login(email: string, password: string): Promise<LoginResponse> {
    return await this.apiFetch('/auth/login', { method: 'POST', body: { email, password } })
  }

  async signup(email: string, password: string): Promise<LoginResponse> {
    return await this.apiFetch('/auth/signup', { method: 'POST', body: { email, password } })
  }

  async forgotPassword(email: string): Promise<ForgotPasswordResponse> {
    return await this.apiFetch('/auth/forgot_password', { method: 'POST', body: { email } })
  }

  async resetPassword(uid: string, token: string, password: string): Promise<LoginResponse> {
    return await this.apiFetch('/auth/reset_password', { method: 'POST', body: { uid, token, password } })
  }

  async smartOnFhirLaunch(): Promise<SmartOnFhirLaunchResponse> {
    const urlSearchParams = document.location.search
    return await this.apiFetch(`/auth/smart_on_fhir_launch${urlSearchParams}`, { method: 'GET' })
  }

  async smartOnFhirCallback(): Promise<SmartOnFhirCallbackResponse> {
    const urlSearchParams = document.location.search
    return await this.apiFetch(`/auth/smart_on_fhir_callback${urlSearchParams}`, { method: 'GET' })
  }
}

export interface AccessTokenSchema {
  token: string
  expireTimestamp: number
}

export interface LoginResponse {
  email: string
  accessToken: AccessTokenSchema
}

export interface ForgotPasswordResponse {
  email: string
}

export interface SmartOnFhirLaunchResponse {
  authorizeUrl: string
}

export interface SmartOnFhirCallbackResponse {
  patientId: string | null
  userLogin: LoginResponse | null
}
