import { deserializeFileJSON, type MedicalFile } from './patient.api'
import type { $Fetch } from 'ofetch'

export interface MedicalFileUpdateInput {
  isReviewed: boolean
}

export interface getSignedUrlResponse {
  url: string
}

export class FileApi {
  constructor(private readonly apiFetch: $Fetch) {}

  async getSignedUrl(
    patientId: string,
    file: MedicalFile,
    response_disposition?: string,
  ): Promise<getSignedUrlResponse> {
    return await this.apiFetch(`/patients/${patientId}/files`, {
      query: {
        path: file.path,
        response_disposition: response_disposition,
      },
    })
  }

  async update(patientId: string, fileId: string, fileData: MedicalFileUpdateInput): Promise<MedicalFile> {
    const response = await this.apiFetch(`/patients/${patientId}/files/${fileId}`, {
      method: 'PATCH',
      body: fileData,
    })
    return deserializeFileJSON(response)
  }
}
