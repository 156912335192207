import type { $Fetch } from 'ofetch'

export enum Sex {
  FEMALE = 'FEMALE',
  MALE = 'MALE',
}

export enum ChartStatus {
  UNSTARTED = 'UNSTARTED',
  IN_PROGRESS = 'IN_PROGRESS',
  COMPLETE = 'COMPLETE',
}

export interface Patient {
  id: string
  firstName: string
  lastName: string
  sex: Sex | null
  birthDate: Date
  chartStatus?: ChartStatus
  noCodesFound: boolean
  dueDate: Date
  organizationName: string | null
  pdfFilesCount: number | null
  ccdaFlowStatus: string | null
  createdAt: Date
  updatedAt: Date
}

export type PaginatedResponse<T> = {
  results: T[]
  count: number
}

export type PatientListResponse = PaginatedResponse<Patient>

export interface PatientJson {
  id: string
  firstName: string
  lastName: string
  sex: Sex | null
  birthDate: string
  chartStatus?: ChartStatus
  dueDate: string
  noCodesFound: boolean
  organizationName: string | null
  pdfFilesCount: number | null
  ccdaFlowStatus: string | null
  createdAt: string
  updatedAt: string
}

export interface Organization {
  id: string
  name: string
  disableAddPatientForm: boolean
}

export function deserializeFileJSON(file: RawMedicalFile): MedicalFile {
  return {
    ...file,
    documentDate: file.documentDate == null ? null : new Date(file.documentDate),
  }
}

export type PatientStatusType = 'incomplete' | 'complete' | undefined

export type getPatientListParams = {
  organizationId?: string
  status?: PatientStatusType
  search?: string
  page?: number
}

export class PatientApi {
  constructor(private readonly apiFetch: $Fetch) {}

  async getPatientList(params?: getPatientListParams): Promise<PatientListResponse> {
    const response = await this.apiFetch('/patient', { query: params })
    response.results = response.results.map(this.deserializePatientJSON)
    return response
  }

  async getPatient(patientId: string): Promise<Patient> {
    const response = await this.apiFetch(`/patient/${patientId}`)
    return this.deserializePatientJSON(response)
  }

  async addPatient(formValues: object): Promise<Patient> {
    const response = await this.apiFetch(`/patient`, {
      method: 'POST',
      body: formValues,
    })
    return this.deserializePatientJSON(response)
  }

  async getPatientCcdaPdfFiles(patientId: string): Promise<PatientFilesResponse> {
    const response = await this.apiFetch(`/patient/files/ccda-pdf/${patientId}`)
    return {
      ...response,
      files: response.files.map(deserializeFileJSON),
    }
  }

  async getPatientCcdaUnstructMedicationsData(patientId: string): Promise<PatientMedicationsResponse> {
    return await this.apiFetch(`/patient/medications/${patientId}`)
  }

  async getPatientCcdaUnstructVitalsData(patientId: string): Promise<PatientVitalsResponse> {
    return await this.apiFetch(`/patient/vitals/${patientId}`)
  }

  async getPatientLabs(patientId: string): Promise<PatientLabsResponse> {
    return await this.apiFetch(`/patient/labs/${patientId}`)
  }

  async startChart(id: string): Promise<void> {
    await this.apiFetch(`/patient`, {
      method: 'PUT',
      body: { id, chartStatus: ChartStatus.IN_PROGRESS },
    })
  }

  async completeChart(id: string): Promise<void> {
    await this.apiFetch(`/patient`, {
      method: 'PUT',
      body: { id, chartStatus: ChartStatus.COMPLETE },
    })
  }

  async setNoCodesFound(id: string, value: boolean) {
    await this.apiFetch(`/patient`, {
      method: 'PUT',
      body: { id, noCodesFound: value },
    })
  }

  async getOrganizationList(): Promise<Organization[]> {
    return await this.apiFetch(`/organization`)
  }

  private deserializePatientJSON(patient: PatientJson): Patient {
    return {
      ...patient,
      birthDate: new Date(patient.birthDate),
      dueDate: new Date(patient.dueDate),
      createdAt: new Date(patient.createdAt),
      updatedAt: new Date(patient.updatedAt),
    }
  }
}

interface BaseMedicalFile {
  id: string
  patientId: string
  name: string
  size?: number
  pageCount: number | null
  type: string
  path: string
  encounterType: string | null
  speciality: string | null
  isReviewed: boolean
  providerOrganization: string | null
  sourceHasDischargeSummaries: boolean
}

export interface RawMedicalFile extends BaseMedicalFile {
  documentDate: string | null
}

export interface MedicalFile extends BaseMedicalFile {
  documentDate: Date | null
}

export interface PatientFilesResponse {
  files: MedicalFile[]
}

export interface PatientMedicationsResponse {
  medications: string[]
}

export interface PatientVitalsResponse {
  vitals: string[]
}

export interface PatientLabsResponse {
  labs: PatientLabs
  labsStructured: PatientLabsStructured[]
}

export interface PatientLabsStructured {
  date: string
  description: string
  value: string
  referenceRange: string
}

export type PatientLabs = string[]
