import { ofetch } from 'ofetch'
import type { $Fetch, FetchResponse } from 'ofetch'
import { useAuth, useRuntimeConfig } from '#imports'

const HTTP_401_UNAUTHORIZED = 401

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const onResponseError = async ({ response }: { response: FetchResponse<any> }) => {
  // exported for testing
  if (response?.status === HTTP_401_UNAUTHORIZED) {
    const { signOut } = useAuth()
    await signOut({
      callbackUrl: '/login',
    })
  }
}

export const getBaseUrl = (): string => {
  // exported for testing
  const runtimeConfig = useRuntimeConfig()
  let baseUrl = runtimeConfig.public.apiBaseUrl
  if (baseUrl == null) {
    throw new Error('runtimeConfig.public.apiBaseUrl is not set')
  }
  if (!baseUrl.endsWith('/')) {
    baseUrl = baseUrl + '/'
  }
  baseUrl = baseUrl + 'api'
  return baseUrl
}

export function useApiFetch(): $Fetch {
  const { token } = useAuth()

  return ofetch.create({
    baseURL: getBaseUrl(),
    headers: {
      Authorization: token?.value ?? '',
    },
    // @ts-ignore
    onResponseError: onResponseError,
  })
}
